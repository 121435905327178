import React, { useState, useEffect } from 'react';
import { Layout } from 'antd';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Header from '../../components/PageLayout/Header';
import SidebarWrapper from '../../components/PageLayout/Sidebar';
import SEO from '../../components/Seo';
import StripeButton from '../../components/StripeButton';
import Comment from '../../components/Comment';
import Config from '../../../config';
import Utils from '../../utils/pageUtils';
// import { Checkout } from 'gatsby-theme-stripe-checkout-button';

import 'prismjs/themes/prism-solarizedlight.css';
import './highlight-syntax.less';
import style from './post.module.less';

const Post = ({ data }) => {
  const { html, frontmatter } = data.markdownRemark;
  const {
    title, cover: { childImageSharp: { fluid } }, excerpt, path, videoURL, worksheetURL_question_1, worksheetURL_answer_1
  } = frontmatter;

  const canonicalUrl = Utils.resolvePageUrl(
    Config.siteUrl,
    Config.pathPrefix,
    path,
  );

  // Client-side Runtime Data Fetching
  const [watchers_count, setStarsCount] = useState(0)
  useEffect(() => {
    // get data from GitHub api
    fetch(`https://zw1azzetm5.execute-api.ap-southeast-1.amazonaws.com/dev`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setStarsCount(resultData)
      }) // set data for the number of stars
  }, [])

  console.log(watchers_count)

    // Client-side Runtime Data Fetching
  const [watchers_count2, setStarsCount2] = useState(0)
  useEffect(() => {
    // get data from GitHub api
    fetch(`https://zw1azzetm5.execute-api.ap-southeast-1.amazonaws.com/dev`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setStarsCount(resultData)
      }) // set data for the number of stars
  }, [])

  console.log(watchers_count2)

  let VideoDivStyles = {
    width: '720px',
    height: '810px',
    position: 'relative',
  }

  let alignCentre = {
    'text-align': 'center'
  }

  let sellfyStyles = {
    'border': 'none'
  }

   let stripeStyles = {
    'background-color':'#6772E5',
    'color':'#FFF',
    'padding':'8px 12px',
    'border':'0',
    'border-radius':'4px',
    'font-size':'1em'
  }

  return (
    <Layout className="outerPadding">
      <Layout className="container">
        <SEO
          title={title}
          description={excerpt}
          path={path}
          keywords={['']}
        />
        <Header />
        <SidebarWrapper>
          <div className="marginTopTitle">
            <h1>{title}</h1>

            <div className={style.bannerImgContainer}> 
              <iframe autoplay="false" frameborder="0" width="100%" height="520px" src={videoURL} allowFullScreen="allowFullScreen"></iframe>
            </div>

            <article className={style.blogArticle} dangerouslySetInnerHTML={{ __html: html }} /><br/>

            <div style={alignCentre}>
            <a href={worksheetURL_question_1} rel="nofollow"><img src="https://i.ibb.co/2M63ymN/button.png" /></a><script type="text/javascript" src="https://transactions.sendowl.com/assets/sendowl.js" ></script>
            </div>
            <br/>

<br/>

<div id="error-message"></div>

          </div>
        </SidebarWrapper>
      </Layout>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($postPath: String!) {
    markdownRemark(frontmatter: { path: { eq: $postPath } }) {
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "DD MMM YYYY")
        tags
        path
        videoURL
        worksheetURL_question_1
        worksheetURL_answer_1
        excerpt
        cover {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { path: { ne: $postPath } }
        fileAbsolutePath: { regex: "/index.md$/" }
      }
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            tags
            worksheetURL_question_1
            worksheetURL_answer_1
            videoURL
            excerpt
            cover {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;

            // <video width="100%" height="520px" class="embed-responsive-item" controls>
            //   <source src={videoURL} type="video/mp4" />
            // </video>

export default Post;
            
            // <div style={alignCentre}>
            // <a href="https://sellfy.com/p/xfdzl1/" id="xfdzl1" class="sellfy-buy-button" data-text="Buy Worksheet Questions"></a>
            // </div>
            // <br/>

            // <div style={alignCentre}>
            // <button><a href="http://sllwi.re/p/1QZ" target='_blank'>Sellwire Test</a></button>
            // </div>
            // <br/>

            // <div style={alignCentre}>
            // <a href="https://sellfy.com/p/xfdzl1/" id="xfdzl1" class="sellfy-buy-button" data-text="Buy Worksheet Questions"></a>
            // </div>
            // <br/>

            // <div style={alignCentre}>
            // <a class="gumroad-button" href={worksheetURL_question_1} target="_blank" data-gumroad-single-product="true">Buy Worksheet Questions</a>
            // </div>
            // <br/>
            // <div style={alignCentre}>
            // <a class="gumroad-button" href={worksheetURL_answer_1} target="_blank" data-gumroad-single-product="true">Buy Worksheet Answers</a>
            // </div>
            // <br/>
